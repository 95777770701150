<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <Navbar />
        <v-row justify="center" :class="[$vuetify.breakpoint.smAndDown ? 'ma-0' : 'ma-0 mx-10 px-10 pt-10']">
            <v-col cols=12 md=6>
            <v-alert v-if="$route.query.register">{{ $t("login.confirm") }}</v-alert>
            <v-alert v-if="$route.query.users">{{ $t("login.register") }}</v-alert>
            <v-card class="py-10 px-5 mx-3" flat style="border-radius: 20px">
                <div class="primary--text display-1 ml-5 mb-5 font-weight-medium">{{ $t("login.welcome") }}</div>
                <v-form :class="[$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-3']" ref="loginForm" @submit.prevent="login">
                    <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
                    <InputField 
                        rules="required"
                        :label="$t('login.username')"
                        icon="account_circle"
                        v-model="username"
                    />
                    <InputField 
                        rules="required"
                        :label="$t('login.password')"
                        icon="lock"
                        inputType="password"
                        v-model="password"
                    />
                    <router-link :to="{ name: 'recover' }" ><v-btn text color="primary" class="text-none plain">{{ $t("login.forgot") }}</v-btn></router-link>
                    <v-row justify="center">
                        <v-btn depressed rounded color="primary" class="mx-0 mt-5 px-10" type="submit" :loading="loading">{{ $t("login.login") }}</v-btn>
                    </v-row>
                    <v-row justify="center">
                        <router-link :to="{ name: 'register' }"><v-btn text class="mx-0 mt-5 text-none plain grey--text">{{ $t("login.noAccount") }}<span class="primary--text ml-1">{{ $t("login.createAccount") }}</span></v-btn></router-link>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'
import InputField from '@/components/partials/InputField'
import PopupDialog from '@/components/partials/PopupDialog'

    export default {
        name: "Login",
        components: { Navbar, InputField, PopupDialog },
        data() {
            return {
                username: '',
                password: '',
                loading: false,
                error: '',
                dialog: false
            }
        },
        methods: {
            login() {
                if (this.$refs.loginForm.validate()) {
                    this.$gtag && this.$gtag.event('login', { method: 'Google' })
                    this.loading = true
                    this.$store.dispatch('retrieveToken', {
                        username: this.username,
                        password: this.password,
                    })
                        .then(() => {
                            this.error = ''
                            this.loading = false;
                            if (this.$route.query.nextUrl) {
                                this.$router.push(this.$route.query.nextUrl)
                            } else {
                                this.$store.state.loggedInFlag == '2' ? this.$router.push({ name: 'WebAppHome' }) : this.$router.push({ name: 'WebAppDashboard' })
                            }
                        })
                        .catch(error => {
                            if (error === "Password is wrong") {
                                this.error = this.$t("login.incorrectPassword")
                            } else if (error === "User not found") {
                                this.error = this.$t("login.userNotFound")
                            } else if (error === "Email is not verified") {
                                this.error = this.$t("login.emailNotVerified")
                            } else {
                                this.dialog = true
                            }
                            this.loading = false
                        })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>